.main-title {
    font-family: var(--title-font);
    font-size: 4.8em;
}

.home-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url('https://source.unsplash.com/Wmuu76rFl0Y/1000x600'); */
    background-image: url(/static/media/pink-smoke.6986244a.jpg);
    background-size: cover;
    /* background-position: top; */
    /* background-image: url('https://source.unsplash.com/2M_sDJ_agvs/1000x800'); */
}

.search-container {
    display: flex;
    align-items: center;
    height: 3.5em;
}

.home-search-box-wrapper {
    width: 24em;
    height: 100%;
    margin-right: 1em;
}

.home-search-box {
    height: 100%;
}

.search-button {
    padding: 0.5em 0.9em;
    border-radius: 0.5em;
    font-size: 1.4em;
    background-color: var(--dark-button-color);
    border: none;
    cursor: pointer;
    height: 100%;
}

.navigation-btns-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.4em;
}

.navigation-button {
    padding: 1.5em 1.2em;
    margin: 1em;
    border-radius: 0.7em;
    background-color: var(--light-button-color);
    font-size: 1.4em;
    font-weight: bold;
    border: none;
    cursor: pointer;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
}

.navigation-button:hover {
    background-color: var(--dark-button-color);
    color: white;
}

@media(min-width: 360px) {
    .navigation-btns-container {
        margin: 2em;
    }
    .navigation-button {
        padding: 1.7em 1.5em;
    }
}

@media(min-width: 768px) {
    .main-title {
        font-size: 6em;
    }

    .search-container {
        margin-bottom: 1em;
        height: 4em;
    }

    .search-button {
        font-size: 1.6em;
        padding: 0.5em 1em;
        margin: 0 0.3em;
    }

    .navigation-btns-container {
        margin: 0.5em;
    }

    .navigation-button {
        padding: 1.5em;
        font-size: 1.5em;
    }

    .home-search-box-wrapper {
        width: 40em;
    }
}
.about-text-title {
    font-weight: bold;
}

.about-form-body {
    width: 100%;
    height: 73%;
    overflow-y: auto;
}

.about-form-footer {
    width: 100%;
    height: 16%;
}

.about-form-text {
    font-family: var(--main-text-font);
    font-size: 1.5rem;
    text-align: center;
    margin: .5em;
    letter-spacing: .05rem;
    line-height: 1.5;
}

@media(min-width: 368px) {
    .about-form-text {
        font-size: 1.6rem;
    }
}

@media(min-width: 768px) {
    .about-form-text {
        font-size: 1.7rem;
    }
}
.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-map-screen-container {
    width: 95%;
    height: 95%;
    background-color: var(--light-button-color);
    border: 2px solid var(--dark-button-color);
    border-radius: 1em;
    display: flex;
}

.search-map-screen-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.search-map-screen-container-section {
    margin: 1em auto;
    width: 95%;
}

.map-screen-section-title {
    margin: 0;
    font-family: var(--title-font);
    font-size: 2rem;
    text-align: center;
    padding: .5em 0;
}

.map-search-btns-container {
    width: 95%;
    margin: .5em auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.icon {
    margin: 0.2rem;
}

.current-location-button {
    width: 6.7em;
    background-color: var(--dark-button-color);
    color: white;
    font-size: 1.3rem;
    padding: 0.5em;
    border: 1px solid transparent;
    border-radius: 3px;
    font-weight: bold;
}

.current-location-button:hover {
    background-color: rgb(255, 158, 30);
}

.separator-text {
    margin: 0 0.8em;
    font-family: var(--main-text-font);
    font-size: 1.2rem;
}

.map-search-box-container {
    width: 60%;
    height: 3em;
}

.search-box-container {
    margin: 0;
    height: 100%;
}

.search-box {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 0.3em;
    box-shadow: var(--box-shadow);
    outline: none;
    padding: 2px 10px;
    font-size: 1.3rem;
}

.search-map-container {
    width: 95%;
    height: 250px;
    margin: auto;
}

.filter-map-buttons {
    text-align: center;
    margin: 0 0 0.5rem 0;
}

.filter-button {
    font-size: 1.3rem;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    border: none;
    color: white;
    font-weight: bold;
    margin: 0.5em;
    text-decoration: none;
}

.all-filter {
    background-color: rgb(25, 125, 255);
}

.all-filter:hover {
    background-color: rgb(67, 149, 255);
}

.not-found-filter {
    background-color: rgb(16, 168, 16);
}

.not-found-filter:hover {
    /* background-color: rgb(14, 155, 14); */
    background-color: rgb(40, 187, 40);
}

.found-filter {
    background-color: rgb(253, 60, 60);
}

.found-filter:hover {
    background-color: rgb(255, 80, 80);
}

.search-form-button {
    font-size: 1.5rem;
    color: white;
    padding: .5em;
    border-radius: .3em;
    background-color: var(--dark-button-color);
    text-decoration: none;
    font-weight: bold;
    border: none;
    font-weight: bold;
    margin: .5em;
}

.info-window-container {
    display: flex;
    align-items: center;
    margin: .5em;
}
.info-window-image {
    width: 50px;
    height: 50px;
}

.info-window-section {
    margin: .5em;
}

.info-window-title {
    font-family: var(--main-text-font);
    font-weight: bold;
    margin: 0;
    font-size: 1.2rem;
}

.info-window-status {
    font-family: var(--main-text-font);
    font-size: 1rem;
}

.info-window-link {
    font-family: var(--main-text-font);
    font-size: 1.2rem;
    margin: 0;
}

.desktop-map {
    display: none;
}

@media(min-width: 360px) {
    .filter-button {
        padding: 0.6em 1.2em;
    }
    .info-window-image {
        width: 70px;
        height: 70px;
    }
    .info-window-title, .info-window-link {
        font-size: 1.5rem;
    }
    .info-window-status {
        font-size: 1.2rem;
    }
    .current-location-button {
        font-size: 1.3rem;
    }
    .search-box {
        font-size: 1.5rem;
    }
    .search-map-screen-container {
        width: 342px;
    }
}

@media(min-width: 768px) {
    .search-map-screen-container {
        width: 90%;
        padding: 1em;
    }
    /* .search-map-screen-container-section{
        width: 85%;
    } */
    .desktop-map {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 100%;
    }
    .desktop-map-container {
        height: 80%;
    }
    .search-map-screen-main {
        width: 50%;
        padding: 2em 0;
    }
    .current-location-button, .search-box {
        font-size: 1.5rem;
    }
    .current-location-button {
        width: 60%;
        padding: 0.8em;
    }
    .map-search-box-container {
        height: 3.5em;
    }
    .map-section {
        display: none;
    }
    .map-search-btns-container {
        flex-direction: column;
        border: 1px solid black;
        border-radius: .3em;
        padding: 2.3em;
        width: 100%;
        box-shadow: 0 .2rem .6rem rgba(0, 0, 0, 0.3);
    }
    .separator-text {
        margin: 0.7em;
        font-size: 1.5rem;
    }
    .map-search-box-container {
        width: 90%;
        height: 4em;
    }
    .filter-button {
        font-size: 1.5rem;
    }
    .filter-map-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        border-radius: 0.3em;
        /* padding: 1.5em; */
        padding: 1em 0;
        width: 100%;
        box-shadow: 0 .2rem .6rem rgba(0, 0, 0, 0.3);
        font-size: 1.5em;
    }
    .map-screen-section-title {
        font-size: 2.5rem;
    }
    .search-form-button {
        padding: 0.8em 1em;
        font-size: 1.7rem;
    }
    .search-box {
        height: 2.7em;
    }
}

@media(min-width: 1200px) {
    .search-map-screen-container {
        /* width: 1140px; */
        width: 1000px;
    }
}

@media(min-height: 768px) {
    .search-map-screen-container {
        height: 590px;
    }
}

@media(min-height: 1200px) {
    .search-map-screen-container {
        height: 685px;
    }
}
.form-screen {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.success-image-background {
    background-image: url('https://source.unsplash.com/DbwYNr8RPbg/1200x1000');
    /* background-image:url('../assets/blue-sky.jpg'); */
    /* background-repeat: no-repeat; */
    background-size: cover;
    background-position: center;
}

.add-item-form {
    width: 90%;
    height: 95%;
    border: 2px solid var(--dark-button-color);
    background-color: var(--light-button-color);
    border-radius: 1em;
}

.form-header, .form-footer {
    width: 100%;
    height: 11%;
}

.form-body {
    width: 100%;
    height: 78%;
    overflow-y: auto;
}

.form-title {
    font-size: 3rem;
    font-family: var(--title-font);
    margin: 0;
}

.form-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    align-items: center;
    margin: 1em auto
}

.form-section-title {
    font-family: var(--title-font);
    font-weight: bold;
    font-size: 1.8rem;
    text-align: center;
    margin: .5em;
}

.input-box {
    background-color: white;
    box-sizing: border-box;
    width: 200px;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    font-size: 1.2rem;
    outline: none;
    padding: .5rem 1rem;
}

.add-item-search-box-wrapper {
    width: 200px;
    margin: 1em 0;
}

.form-text { 
    font-family: var(--main-text-font);
    font-size: 1.5rem;
    text-align: center;
    margin: .5em;
    letter-spacing: .05rem;
    line-height: 1.5;
}

.new-hint-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.hint-listings {
    width: 80%;
    margin: 1.5em;
    border: 1px solid black;
    border-radius: .5em;
}

.hint-listing {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
}

.hl-text-wrapper {
    flex: 1 1;
}

.hint-listing-text {
    padding-left: 1em;
    font-size: 1.2rem;
    overflow-wrap: break-word;
}

.no-hints-message {
    font-family: var(--main-text-font);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding-top: 1em;
}

.form-button, .add-hint-button {
    background-color: var(--dark-button-color);
    font-size: 1.3em;
    padding: 0.5em 1em;
    border-radius: .3em;
    border: none;
    color: white;
    font-weight: bold;
    margin: .5em;
    text-decoration: none;
}

.submit-button {
    background-color: var(--dark-button-color);
    font-size: 1.4rem;
    padding: .5em;
    border-radius: .3em;
    border: none;
    color: white;
    font-weight: bold;
    letter-spacing: .1rem;
}

.id-box {
    height: 4rem;
}

.id-text {
    margin: 0;
    font-size: 2rem;
    text-align: center;
    padding: .5rem;
}

.description-box {
    height: 7em;
    font-size: 1.4em;
}

.form-btns-container {
    text-align: center;
}

.fill-height {
    height: 100%;
}

@media(min-width: 368px) {
    .description-box {
        width: 18em;
    }
    .add-item-form {
        width: 330px;
    }
    .submit-button {
        font-size: 1.6rem;
    }
    .form-text {
        font-size: 1.6rem;
    }
    .form-button {
        font-size: 1.4rem;
        padding: 0.8em 1em;
    }
    .input-box, .add-item-search-box-wrapper {
        width: 245px;
    }
}

@media(min-width: 768px) {
    .add-item-form {
        width: 550px;
        border: 4px solid var(--dark-button-color);
    }
    .form-title {
        font-size: 4rem;
    }
    .form-section-title {
        font-size: 2.5rem;
    }
    .input-box, .add-item-search-box-wrapper {
        font-size: 2rem;
        width: 90%;
    }
    .form-button {
        font-size: 1.6em;
    }
    .form-text {
        font-size: 1.7rem;
    }
    .description-box {
        height: 100px;
    }
    .hint-listing-text {
        font-size: 1.5rem;
    }
    .add-hint-button {
        font-size: 1.65rem;
    }
    .id-box {
        width: 80%;
    }
    .submit-button {
        font-size: 2rem;
    }
}

@media(min-height: 768px) {
    .add-item-form {
        height: 85%;
    }
}
.map-detail-container, .image-container, .hints-container {
    border: 1px solid black;
}

.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

hr {
    margin: 0 auto;
    width: 80%;
    border: .5px solid var(--dark-button-color);
}

.item-details-container {
    height: 90%;
    width: 30em;
    border: 2px solid var(--dark-button-color);
    background-color: var(--light-button-color);
    border-radius: 1em;
}

.item-detail-form {
    height: 100%;
    width: 100%;
}

.item-detail-form-header {
    height: 15%;
}

.item-detail-title {
    margin: 0.5em;
    font-family: var(--title-font);
    font-size: 2em;
    text-align: center;
}
.item-detail-form-body {
    height: 70%;
    overflow-y: auto;
}

.item-detail-body-section {
    width: 90%;
    margin: 1em auto;
}

.item-detail-map-container {
    height: 20em;
    margin: auto;
    border: 2px solid var(--dark-button-color);
}

.item-detail-image-container {
    width: 45%;
    padding-top: 45%;
    border: 2px solid var(--dark-button-color);
}

.item-details-section-title {
    margin: 0;
    font-family: var(--title-font);
    font-size: 2rem;
    text-align: center
}

.status-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.item-detail-status-section {
    width: 55%;
}

.item-details-status-text {
    font-family: var(--main-text-font);
    font-size: 1.5rem;
    margin: 0;
    padding: .5em;
    text-align: center;
}

.status-comment-container {
    display: flex;
    justify-content: center;
    padding: 1em .5em;
}

.status-comment-font {
    font-family: var(--main-text-font);
    font-size: 1.2rem;
    margin: 0;
    text-align: center;
}

.hints-container {
    margin: .5em auto;
    border: 1px solid black;
    border-radius: .5em;
}

.item-detail-hint-listing {
    margin: 0;
    font-family: var(--main-text-font);
    font-size: 1.2rem;
    padding: .5em;
    text-align: center;
    border-bottom: 1px solid black;
}

.item-detail-form-footer {
    height: 15%;
}

.detail-screen-button {
    background-color: var(--dark-button-color);
    font-size: 1.2rem;
    width: 7em;
    height: 3em;
    margin: 0 1em;
    padding: .5em 1em;
    border-radius: .3em;
    border: none;
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.desktop-map-display {
    display: none;
}

@media(min-width: 768px) {
    .mobile-map-display {
        display: none;
    }
    .item-details-container {
        width: 90%;
        height: 85%;
        display: flex;
    }
    .item-detail-form {
        width: 45%;
    }
    .desktop-map-display {
        width: 55%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-detail-map-container {
        width: 90%;
        height: 80%;
    }
    .detail-screen-button {
        font-size: 1.5rem;

    }
}

@media(min-width: 1200px) {
    .item-details-container {
        width: 70%;
    }
}

.submit-success-form-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    align-items: center;
    margin: 0.5em auto
}

.submit-success-form-header {
    width: 100%;
    height: 12%
}

.submit-success-form-body {
    width: 100%;
    height: 80%;
    overflow-y: auto;
}
.found-items-image-background {
    background-image: url('https://source.unsplash.com/DbwYNr8RPbg/1200x1000');
    background-size: cover;
    background-position: center;
}

.found-items-form {
    width: 90%;
    height: 95%;
    margin: 1.5em auto;
    align-items: center;
    border: 2px solid var(--dark-button-color);
    background-color: var(--light-button-color);
    border-radius: 1em;
}

.found-items-form-body {
    width: 100%;
    height: 74%;
    overflow-y: auto;
}

.search-items-button {
    padding: 0.5em 0.7em;
    border-radius: 0.5em;
    font-size: 1.4em;
    background-color: var(--dark-button-color);
    border: none;
    cursor: pointer;
    margin: auto;
}

.found-items-form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em auto;
}

.found-title {
    text-align: center;
    padding: 0.3em 0 0;
}

.search-id-form-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.search-id-input-box {
    height: 3rem;
    margin: 0.6rem;
    background-color: white;
    box-sizing: border-box;
    width: 18rem;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    font-size: 1.4rem;
    outline: none;
    padding: .5rem 1rem;
}

.found-items-buttons-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em auto;
}

.found-comment-thank-you {
    font-size: 1.5rem;
    font-family: var(--main-text-font);
    font-weight: bold;
    width: 60%;
    margin: auto;
    padding: 1em 0;
    text-align: center;
}

@media(min-width: 368px) {
    .found-comment-thank-you {
        font-size: 1.6em;
    }
}

@media(min-width: 768px) {
    .found-items-form {
        height: 90%;
        width: 55rem;
        border: 4px solid var(--dark-button-color);
    }
    .search-id-input-box {
        height: 3.7rem;
        width: 24rem;
        margin: 0.7rem 1.6rem;
        font-size: 2rem;
    }
    .search-items-button {
        font-size: 1.6em;
        padding: 0.65em 1em;
    }
    .found-comment-thank-you {
        font-size: 1.7em;
    }
}
.not-found-image-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: space-between;
    background-image: url('https://source.unsplash.com/2M_sDJ_agvs/4000x3000');
    background-size: cover;
    background-position: center;
}

.not-found-title {
    /* border: 0.3rem solid white;
    border-radius: 0.2em;
    padding: 1.5rem; */
    font-family: var(--title-font);
    font-size: 3.5em;
    color: black;
    margin: 0.5em 0;
}

.not-found-text {
    font-family: 'Neuton', serif;
    font-size: 2.3em;
    margin: 0 0.6em;
}

.little-dots {
    font-size: 3em;
    color: white;
}

.little-star {
    font-size: 0.8em;
}

