.form-screen {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.success-image-background {
    background-image: url('https://source.unsplash.com/DbwYNr8RPbg/1200x1000');
    /* background-image:url('../assets/blue-sky.jpg'); */
    /* background-repeat: no-repeat; */
    background-size: cover;
    background-position: center;
}

.add-item-form {
    width: 90%;
    height: 95%;
    border: 2px solid var(--dark-button-color);
    background-color: var(--light-button-color);
    border-radius: 1em;
}

.form-header, .form-footer {
    width: 100%;
    height: 11%;
}

.form-body {
    width: 100%;
    height: 78%;
    overflow-y: auto;
}

.form-title {
    font-size: 3rem;
    font-family: var(--title-font);
    margin: 0;
}

.form-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    align-items: center;
    margin: 1em auto
}

.form-section-title {
    font-family: var(--title-font);
    font-weight: bold;
    font-size: 1.8rem;
    text-align: center;
    margin: .5em;
}

.input-box {
    background-color: white;
    box-sizing: border-box;
    width: 200px;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    font-size: 1.2rem;
    outline: none;
    padding: .5rem 1rem;
}

.add-item-search-box-wrapper {
    width: 200px;
    margin: 1em 0;
}

.form-text { 
    font-family: var(--main-text-font);
    font-size: 1.5rem;
    text-align: center;
    margin: .5em;
    letter-spacing: .05rem;
    line-height: 1.5;
}

.new-hint-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.hint-listings {
    width: 80%;
    margin: 1.5em;
    border: 1px solid black;
    border-radius: .5em;
}

.hint-listing {
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
}

.hl-text-wrapper {
    flex: 1;
}

.hint-listing-text {
    padding-left: 1em;
    font-size: 1.2rem;
    overflow-wrap: break-word;
}

.no-hints-message {
    font-family: var(--main-text-font);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding-top: 1em;
}

.form-button, .add-hint-button {
    background-color: var(--dark-button-color);
    font-size: 1.3em;
    padding: 0.5em 1em;
    border-radius: .3em;
    border: none;
    color: white;
    font-weight: bold;
    margin: .5em;
    text-decoration: none;
}

.submit-button {
    background-color: var(--dark-button-color);
    font-size: 1.4rem;
    padding: .5em;
    border-radius: .3em;
    border: none;
    color: white;
    font-weight: bold;
    letter-spacing: .1rem;
}

.id-box {
    height: 4rem;
}

.id-text {
    margin: 0;
    font-size: 2rem;
    text-align: center;
    padding: .5rem;
}

.description-box {
    height: 7em;
    font-size: 1.4em;
}

.form-btns-container {
    text-align: center;
}

.fill-height {
    height: 100%;
}

@media(min-width: 368px) {
    .description-box {
        width: 18em;
    }
    .add-item-form {
        width: 330px;
    }
    .submit-button {
        font-size: 1.6rem;
    }
    .form-text {
        font-size: 1.6rem;
    }
    .form-button {
        font-size: 1.4rem;
        padding: 0.8em 1em;
    }
    .input-box, .add-item-search-box-wrapper {
        width: 245px;
    }
}

@media(min-width: 768px) {
    .add-item-form {
        width: 550px;
        border: 4px solid var(--dark-button-color);
    }
    .form-title {
        font-size: 4rem;
    }
    .form-section-title {
        font-size: 2.5rem;
    }
    .input-box, .add-item-search-box-wrapper {
        font-size: 2rem;
        width: 90%;
    }
    .form-button {
        font-size: 1.6em;
    }
    .form-text {
        font-size: 1.7rem;
    }
    .description-box {
        height: 100px;
    }
    .hint-listing-text {
        font-size: 1.5rem;
    }
    .add-hint-button {
        font-size: 1.65rem;
    }
    .id-box {
        width: 80%;
    }
    .submit-button {
        font-size: 2rem;
    }
}

@media(min-height: 768px) {
    .add-item-form {
        height: 85%;
    }
}