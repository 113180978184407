.main-title {
    font-family: var(--title-font);
    font-size: 4.8em;
}

.home-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url('https://source.unsplash.com/Wmuu76rFl0Y/1000x600'); */
    background-image: url('../assets/pink-smoke.jpg');
    background-size: cover;
    /* background-position: top; */
    /* background-image: url('https://source.unsplash.com/2M_sDJ_agvs/1000x800'); */
}

.search-container {
    display: flex;
    align-items: center;
    height: 3.5em;
}

.home-search-box-wrapper {
    width: 24em;
    height: 100%;
    margin-right: 1em;
}

.home-search-box {
    height: 100%;
}

.search-button {
    padding: 0.5em 0.9em;
    border-radius: 0.5em;
    font-size: 1.4em;
    background-color: var(--dark-button-color);
    border: none;
    cursor: pointer;
    height: 100%;
}

.navigation-btns-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.4em;
}

.navigation-button {
    padding: 1.5em 1.2em;
    margin: 1em;
    border-radius: 0.7em;
    background-color: var(--light-button-color);
    font-size: 1.4em;
    font-weight: bold;
    border: none;
    cursor: pointer;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
}

.navigation-button:hover {
    background-color: var(--dark-button-color);
    color: white;
}

@media(min-width: 360px) {
    .navigation-btns-container {
        margin: 2em;
    }
    .navigation-button {
        padding: 1.7em 1.5em;
    }
}

@media(min-width: 768px) {
    .main-title {
        font-size: 6em;
    }

    .search-container {
        margin-bottom: 1em;
        height: 4em;
    }

    .search-button {
        font-size: 1.6em;
        padding: 0.5em 1em;
        margin: 0 0.3em;
    }

    .navigation-btns-container {
        margin: 0.5em;
    }

    .navigation-button {
        padding: 1.5em;
        font-size: 1.5em;
    }

    .home-search-box-wrapper {
        width: 40em;
    }
}