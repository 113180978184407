.submit-success-form-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    align-items: center;
    margin: 0.5em auto
}

.submit-success-form-header {
    width: 100%;
    height: 12%
}

.submit-success-form-body {
    width: 100%;
    height: 80%;
    overflow-y: auto;
}