.not-found-image-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: space-between;
    background-image: url('https://source.unsplash.com/2M_sDJ_agvs/4000x3000');
    background-size: cover;
    background-position: center;
}

.not-found-title {
    /* border: 0.3rem solid white;
    border-radius: 0.2em;
    padding: 1.5rem; */
    font-family: var(--title-font);
    font-size: 3.5em;
    color: black;
    margin: 0.5em 0;
}

.not-found-text {
    font-family: 'Neuton', serif;
    font-size: 2.3em;
    margin: 0 0.6em;
}

.little-dots {
    font-size: 3em;
    color: white;
}

.little-star {
    font-size: 0.8em;
}
