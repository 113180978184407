.found-items-image-background {
    background-image: url('https://source.unsplash.com/DbwYNr8RPbg/1200x1000');
    background-size: cover;
    background-position: center;
}

.found-items-form {
    width: 90%;
    height: 95%;
    margin: 1.5em auto;
    align-items: center;
    border: 2px solid var(--dark-button-color);
    background-color: var(--light-button-color);
    border-radius: 1em;
}

.found-items-form-body {
    width: 100%;
    height: 74%;
    overflow-y: auto;
}

.search-items-button {
    padding: 0.5em 0.7em;
    border-radius: 0.5em;
    font-size: 1.4em;
    background-color: var(--dark-button-color);
    border: none;
    cursor: pointer;
    margin: auto;
}

.found-items-form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em auto;
}

.found-title {
    text-align: center;
    padding: 0.3em 0 0;
}

.search-id-form-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.search-id-input-box {
    height: 3rem;
    margin: 0.6rem;
    background-color: white;
    box-sizing: border-box;
    width: 18rem;
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: var(--box-shadow);
    font-size: 1.4rem;
    outline: none;
    padding: .5rem 1rem;
}

.found-items-buttons-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em auto;
}

.found-comment-thank-you {
    font-size: 1.5rem;
    font-family: var(--main-text-font);
    font-weight: bold;
    width: 60%;
    margin: auto;
    padding: 1em 0;
    text-align: center;
}

@media(min-width: 368px) {
    .found-comment-thank-you {
        font-size: 1.6em;
    }
}

@media(min-width: 768px) {
    .found-items-form {
        height: 90%;
        width: 55rem;
        border: 4px solid var(--dark-button-color);
    }
    .search-id-input-box {
        height: 3.7rem;
        width: 24rem;
        margin: 0.7rem 1.6rem;
        font-size: 2rem;
    }
    .search-items-button {
        font-size: 1.6em;
        padding: 0.65em 1em;
    }
    .found-comment-thank-you {
        font-size: 1.7em;
    }
}