.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-map-screen-container {
    width: 95%;
    height: 95%;
    background-color: var(--light-button-color);
    border: 2px solid var(--dark-button-color);
    border-radius: 1em;
    display: flex;
}

.search-map-screen-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.search-map-screen-container-section {
    margin: 1em auto;
    width: 95%;
}

.map-screen-section-title {
    margin: 0;
    font-family: var(--title-font);
    font-size: 2rem;
    text-align: center;
    padding: .5em 0;
}

.map-search-btns-container {
    width: 95%;
    margin: .5em auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.icon {
    margin: 0.2rem;
}

.current-location-button {
    width: 6.7em;
    background-color: var(--dark-button-color);
    color: white;
    font-size: 1.3rem;
    padding: 0.5em;
    border: 1px solid transparent;
    border-radius: 3px;
    font-weight: bold;
}

.current-location-button:hover {
    background-color: rgb(255, 158, 30);
}

.separator-text {
    margin: 0 0.8em;
    font-family: var(--main-text-font);
    font-size: 1.2rem;
}

.map-search-box-container {
    width: 60%;
    height: 3em;
}

.search-box-container {
    margin: 0;
    height: 100%;
}

.search-box {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 0.3em;
    box-shadow: var(--box-shadow);
    outline: none;
    padding: 2px 10px;
    font-size: 1.3rem;
}

.search-map-container {
    width: 95%;
    height: 250px;
    margin: auto;
}

.filter-map-buttons {
    text-align: center;
    margin: 0 0 0.5rem 0;
}

.filter-button {
    font-size: 1.3rem;
    padding: 0.5em 1em;
    border-radius: 0.3em;
    border: none;
    color: white;
    font-weight: bold;
    margin: 0.5em;
    text-decoration: none;
}

.all-filter {
    background-color: rgb(25, 125, 255);
}

.all-filter:hover {
    background-color: rgb(67, 149, 255);
}

.not-found-filter {
    background-color: rgb(16, 168, 16);
}

.not-found-filter:hover {
    /* background-color: rgb(14, 155, 14); */
    background-color: rgb(40, 187, 40);
}

.found-filter {
    background-color: rgb(253, 60, 60);
}

.found-filter:hover {
    background-color: rgb(255, 80, 80);
}

.search-form-button {
    font-size: 1.5rem;
    color: white;
    padding: .5em;
    border-radius: .3em;
    background-color: var(--dark-button-color);
    text-decoration: none;
    font-weight: bold;
    border: none;
    font-weight: bold;
    margin: .5em;
}

.info-window-container {
    display: flex;
    align-items: center;
    margin: .5em;
}
.info-window-image {
    width: 50px;
    height: 50px;
}

.info-window-section {
    margin: .5em;
}

.info-window-title {
    font-family: var(--main-text-font);
    font-weight: bold;
    margin: 0;
    font-size: 1.2rem;
}

.info-window-status {
    font-family: var(--main-text-font);
    font-size: 1rem;
}

.info-window-link {
    font-family: var(--main-text-font);
    font-size: 1.2rem;
    margin: 0;
}

.desktop-map {
    display: none;
}

@media(min-width: 360px) {
    .filter-button {
        padding: 0.6em 1.2em;
    }
    .info-window-image {
        width: 70px;
        height: 70px;
    }
    .info-window-title, .info-window-link {
        font-size: 1.5rem;
    }
    .info-window-status {
        font-size: 1.2rem;
    }
    .current-location-button {
        font-size: 1.3rem;
    }
    .search-box {
        font-size: 1.5rem;
    }
    .search-map-screen-container {
        width: 342px;
    }
}

@media(min-width: 768px) {
    .search-map-screen-container {
        width: 90%;
        padding: 1em;
    }
    /* .search-map-screen-container-section{
        width: 85%;
    } */
    .desktop-map {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 100%;
    }
    .desktop-map-container {
        height: 80%;
    }
    .search-map-screen-main {
        width: 50%;
        padding: 2em 0;
    }
    .current-location-button, .search-box {
        font-size: 1.5rem;
    }
    .current-location-button {
        width: 60%;
        padding: 0.8em;
    }
    .map-search-box-container {
        height: 3.5em;
    }
    .map-section {
        display: none;
    }
    .map-search-btns-container {
        flex-direction: column;
        border: 1px solid black;
        border-radius: .3em;
        padding: 2.3em;
        width: 100%;
        box-shadow: 0 .2rem .6rem rgba(0, 0, 0, 0.3);
    }
    .separator-text {
        margin: 0.7em;
        font-size: 1.5rem;
    }
    .map-search-box-container {
        width: 90%;
        height: 4em;
    }
    .filter-button {
        font-size: 1.5rem;
    }
    .filter-map-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        border-radius: 0.3em;
        /* padding: 1.5em; */
        padding: 1em 0;
        width: 100%;
        box-shadow: 0 .2rem .6rem rgba(0, 0, 0, 0.3);
        font-size: 1.5em;
    }
    .map-screen-section-title {
        font-size: 2.5rem;
    }
    .search-form-button {
        padding: 0.8em 1em;
        font-size: 1.7rem;
    }
    .search-box {
        height: 2.7em;
    }
}

@media(min-width: 1200px) {
    .search-map-screen-container {
        /* width: 1140px; */
        width: 1000px;
    }
}

@media(min-height: 768px) {
    .search-map-screen-container {
        height: 590px;
    }
}

@media(min-height: 1200px) {
    .search-map-screen-container {
        height: 685px;
    }
}